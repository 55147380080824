<template>
  <div :class="['home', $store.state.lang !== 'zh' ? 'home_en' : '']">
    <!-- p2 -->
    <section class="home-section-0" id="home-section-0">
      <img class="sec-0-img-0" src="../../assets/images/words@2x.png" alt="">
      <img id="sec-0-img-1" class="sec-0-img-1 animate__animated animate__fadeInDown" src="../../assets/images/pic@2x.png" alt="">
      <div class="sec-0-content">
        <img class="sec-0-content-img animate__animated animate__fadeInDown" src="../../assets/images/home-about-logo@2x.png" alt="">
        <div class="sec-0-content-detail animate__animated animate__fadeInDown" id="sec-0-content-detail">
          <p><span class="sec-0-content-detail-icon">&lt;</span>{{ $t('home.msg_1') }}</p>
        </div>
      </div>
      <div class="sec-1-point">
        <p class="sec-1-point-word" v-show="lang === 'zh'">
          <span class="word-1">向</span>
          <span class="word-2">下</span>
          <span class="word-3">滑</span>
          <span class="word-4">动</span>
        </p>
        <p class="sec-1-point-word" v-show="lang !== 'zh'">
          <span class="word-1" style="left: -5px;">Swipe</span>
          <span class="word-3" style="left: -5px;">down</span>
        </p>
        <img class="sec-1-point-img" src="../../assets/images/home-icon_rolling@2x.png" alt="">
      </div>
    </section>
    <!-- p0 -->
    <!-- <section class="about-section-1">
      <img class="sec-1-img-1" src="../../assets/images/words@2x.png" alt="">
      <img class="sec-1-img-2" src="../../assets/images/home-about-logo@2x.png" alt="">
      <div class="sec-1-content animate__animated animate__fadeInDown">
        <div class="sec-1-content-detail">
          <p>{{ $t('home.msg_1') }}</p>
        </div>
      </div>
    </section> -->
    <!-- p1 -->
    <section class="home-section-1" id="home-section-1">
      <img class="sec-1-img-1" src="../../assets/images/words@2x.png" alt="">
      <img class="sec-1-img-2 animate__animated monkey-hide" id="sec-1-img-2" src="../../assets/images/home-P1_pic@2x.png" alt="">
      <!-- <div class="sec-1-point">
        <p class="sec-1-point-word" v-show="lang === 'zh'">
          <span class="word-1">向</span>
          <span class="word-2">下</span>
          <span class="word-3">滑</span>
          <span class="word-4">动</span>
        </p>
        <p class="sec-1-point-word" v-show="lang !== 'zh'">
          <span class="word-1" style="left: -5px;">Swipe</span>
          <span class="word-3" style="left: -5px;">down</span>
        </p>
        <img class="sec-1-point-img" src="../../assets/images/home-icon_rolling@2x.png" alt="">
      </div> -->
      <div class="sec-1-content">
        <p id="sec-1-content-title" class="sec-1-content-title animate__animated monkey-hide">{{ $t('home.msg_2') }}<span class="sec-1-content-title-img"><img src="../../assets/images/home-icon@2x.png" alt=""></span></p>
        <div id="sec-1-content-point" class="sec-1-content-point animate__animated monkey-hide">
          <p class="sec-1-content-point-text">
            <span class="sec-1-content-point-text-line">/</span>
            {{ $t('home.msg_3') }}
          </p>
          <p class="sec-1-content-point-text">
            <span class="sec-1-content-point-text-line">/</span>
            {{ $t('home.msg_4') }}
          </p>
        </div>
        <div id="sec-1-content-detail" class="sec-1-content-detail animate__animated monkey-hide">
          <p><span class="sec-1-content-detail-icon">&lt;</span>{{ $t('home.msg_5') }}</p>
        </div>
      </div>
    </section>
    <!-- p2 -->
    <section class="home-section-2" id="home-section-2">
      <div class="sec-2-content">
        <p class="sec-2-content-title animate__animated monkey-hide" id="sec-2-content-title">{{ $t('home.msg_6') }}</p>
        <div class="sec-2-content-detail animate__animated monkey-hide" id="sec-2-content-detail">
          <p><span class="sec-2-content-detail-icon">&lt;</span>{{ $t('home.msg_7') }}</p>
        </div>
      </div>
      <img id="sec-2-img-1" class="sec-2-img-1 animate__animated monkey-hide" src="../../assets/images/home-p2_pic@2x.png" alt="">
    </section>
    <!-- p3 -->
    <section class="home-section-3" id="home-section-3">
      <img id="sec-3-img-1" class="sec-3-img-1 animate__animated monkey-hide" src="../../assets/images/home-p3_pic@2x.png" alt="">
      <div id="sec-3-content" class="sec-3-content animate__animated monkey-hide">
        <p class="sec-3-content-title">{{ $t('home.msg_8') }}</p>
        <p class="sec-3-content-point">{{ $t('home.msg_9') }}</p>
        <div class="sec-3-content-detail">
          <p><span class="sec-3-content-detail-icon">&lt;</span>{{ $t('home.msg_10') }}</p>
          <p><span class="sec-3-content-detail-icon">&lt;</span>{{ $t('home.msg_11') }}</p>
          <p><span class="sec-3-content-detail-icon">&lt;</span>{{ $t('home.msg_12') }}</p>
        </div>
        <div class="sec-3-content-button" @click="jump('product')">
          <p>{{ $t('home.msg_13') }}</p>
        </div>
      </div>
    </section>
    <!-- p4 --- 备份 -->
    <!-- p4 -->
    <!-- <section class="home-section-4">
      <div class="sec-4-item">
        <div class="sec-4-item-img">
          <img src="../../assets/images/home-left@2x.png" alt="">
        </div>
        <div class="sec-4-item-content">
          <div class="sec-4-item-content-title">
            <p>科普：清洁机器人百科</p>
          </div>
          <div class="sec-4-item-content-time">
            <p>2021 年 3 月 29 日</p>
          </div>
          <div class="sec-4-item-content-button"
          @click="jumpDetail(1)"
          >
            <p>阅读原文</p>
          </div>
        </div>
      </div>
      <div class="sec-4-item">
        <div class="sec-4-item-img">
          <img src="../../assets/images/home-middle@2x.png" alt="">
        </div>
        <div class="sec-4-item-content">
          <div class="sec-4-item-content-title">
            <p>科普：扫地机人的工作原理</p>
          </div>
          <div class="sec-4-item-content-time">
            <p>2021 年 3 月 29 日</p>
          </div>
          <div class="sec-4-item-content-button"
          @click="jumpDetail(2)"
          >
            <p>阅读原文</p>
          </div>
        </div>
      </div>
      <div class="sec-4-item">
        <div class="sec-4-item-img">
          <img src="../../assets/images/home-right@2x.png" alt="">
        </div>
        <div class="sec-4-item-content">
          <div class="sec-4-item-content-title">
            <p>商用清洁机器人----无限潜力的商业蓝海！</p>
          </div>
          <div class="sec-4-item-content-time">
            <p>2021 年 3 月 16 日</p>
          </div>
          <div class="sec-4-item-content-button"
          @click="jumpDetail(3)"
          >
            <p>阅读原文</p>
          </div>
        </div>
      </div>
    </section> -->
    <!-- p4 --- 备份 -->
    <!-- p4 -->
    <section class="home-section-4">
      <div
        class="sec-4-item"
        v-for="(item, index) in newsList" 
        :key="index">
        <div class="sec-4-item-img">
          <img :src="item.cover" alt="">
        </div>
        <div class="sec-4-item-content">
          <div class="sec-4-item-content-title">
            <p>{{ item.title }}</p>
          </div>
          <div class="sec-4-item-content-time">
            <p>{{ item.createTime | formatTime }}</p>
          </div>
          <div class="sec-4-item-content-button"
          @click="jumpDetail(item.uid)"
          >
            <p>{{ $t('home.msg_14') }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as api from '../../common/api'
import dayjs from 'dayjs'
import { localStorage } from '../../common/utils'

export default {
  data () {
    return {
      p0Action: false,
      p2Action: false,
      p3Action: false,
      newsList: [],
      lang: 'zh',
    }
  },
  filters: {
    formatTime: value => {
      if (!value) return ''
      return dayjs(value).format('YYYY年MM月DD日')
    },
  },
  created() {
    this.getNews()
    this.lang = localStorage.get('i18nLang') || 'zh'
  },
  mounted() {
    window.addEventListener('scroll', this.viewScroll, true)
  },
  methods: {
    // 获取新闻
    async getNews () {
      const result = await api.query({
        pageSize: 10,
        pageNum: 1,
      })
      const { data } = result
      this.newsList = data.slice(0, 3)
    },
    viewScroll () {
      const screenHeight = window.screen.height
      const screenWidth = window.screen.width
      const isSmall = screenWidth < 800
      // p1动画效果
      const watchDom0 = document.getElementById('home-section-1')
      const height0 = watchDom0.getBoundingClientRect().top
      if (height0 < screenHeight / 2 && !this.p0Action) {
        console.log('1')
        const dom1 = document.getElementById('sec-1-img-2')
        const dom2 = document.getElementById('sec-1-content-title')
        const dom3 = document.getElementById('sec-1-content-point')
        const dom4 = document.getElementById('sec-1-content-detail')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom2.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom3.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom4.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInRight')
          dom2.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom3.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom4.classList.replace('monkey-hide', 'animate__bounceInLeft')
        }
        this.p0Action = true
      }
      // animate__bounceInLeft
      // p2动画效果
      const watchDom1 = document.getElementById('home-section-2')
      const height1 = watchDom1.getBoundingClientRect().top

      if (height1 < screenHeight / 2 && !this.p2Action) {
        const dom1 = document.getElementById('sec-2-content-title')
        const dom2 = document.getElementById('sec-2-content-detail')
        const dom3 = document.getElementById('sec-2-img-1')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom2.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom3.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInRight')
          dom2.classList.replace('monkey-hide', 'animate__bounceInRight')
          dom3.classList.replace('monkey-hide', 'animate__bounceInLeft')
        }
        this.p2Action = true
      }
      // p3动画效果
      const watchDom2 = document.getElementById('home-section-3')
      const height2 = watchDom2.getBoundingClientRect().top
      if (height2 < screenHeight / 2 && !this.p3Action) {
        const dom1 = document.getElementById('sec-3-img-1')
        const dom2 = document.getElementById('sec-3-content')
        if (isSmall) {
          dom1.classList.replace('monkey-hide', 'animate__fadeInDown')
          dom2.classList.replace('monkey-hide', 'animate__fadeInDown')
        } else {
          dom1.classList.replace('monkey-hide', 'animate__bounceInLeft')
          dom2.classList.replace('monkey-hide', 'animate__bounceInRight')
        }
        this.p3Action = true
      }
    },
    // 跳转
    jump (path) {
      this.$router.push({
        path: '/' + path
      })
    },
    // 跳转新闻
    jumpDetail (id) {
      this.$router.push({
        path: `/detail?id=${id}`,
      })
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .home {
    // p0 css
    .home-section-0 {
      height: 12.3rem;
      position: relative;

      .sec-0-img-0 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .15rem;
        left: 1.56rem;
      }

      .sec-0-img-1 {
        width: 9.64rem;
        height: 9.24rem;
        position: absolute;
        top: .31rem;
        left: .25rem;
      }
      .sec-0-content {
        position: absolute;
        top: 3.41rem;
        left: 10.84rem;

        .sec-0-content-img {
          width: 5.75rem;
          height: 1.21rem;
        }
        
        .sec-0-content-detail {
          padding-top: .78rem;
          width: 6.92rem;
          font-size: .16rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-0-content-detail-icon {
            padding-right: .16rem;
          }
        }

      }

      .sec-1-point {
        position: absolute;
        top: 9.48rem;
        left: 1.42rem;
        .sec-1-point-word {
          position: relative;
          span {
            position: absolute;
            left: .04rem;
            display: block;
            font-size: .16rem;
            color: #C0C2C4;
            font-family: 'SourceHanSansCNRegular';
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
          }

          .word-1 {
            top: -1.28rem;
          }
          .word-2 {
            top: -0.96rem;
          }
          .word-3 {
            top: -0.64rem;
          }
          .word-4 {
            top: -0.32rem;
          }
        }
        .sec-1-point-img {
          width: .25rem;
          height: .33rem;
        }
      }
    }
    // p1 css
    .home-section-1 {
      height: 10.31rem;
      position: relative;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .15rem;
        left: 1.51rem;
        display: none;
      }
      .sec-1-img-2 {
        width: 7.1rem;
        height: 7.82rem;
        position: absolute;
        top: .94rem;
        right: 1.71rem;
      }
      .sec-1-point {
        position: absolute;
        top: 6.48rem;
        left: 1.42rem;
        .sec-1-point-word {
          position: relative;
          span {
            position: absolute;
            left: .04rem;
            display: block;
            font-size: .16rem;
            color: #C0C2C4;
            font-family: 'SourceHanSansCNRegular';
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
          }

          .word-1 {
            top: -1.28rem;
          }
          .word-2 {
            top: -0.96rem;
          }
          .word-3 {
            top: -0.64rem;
          }
          .word-4 {
            top: -0.32rem;
          }
        }
        .sec-1-point-img {
          width: .25rem;
          height: .33rem;
        }
      }
      .sec-1-content {
        position: absolute;
        top: 3.28rem;
        left: 2.88rem;

        .sec-1-content-title {
          font-size: 1rem;
          color: #F0F2F4;

          .sec-1-content-title-img {
            height: 1rem;
            width: 1rem;
            position: relative;
            
            img {
              width: .91rem;
              height: .92rem;
              position: absolute;
              top: 0.04rem;
              left: 0.2rem;
            }
          }
          
        }

        .sec-1-content-point {
          color: #fff;
          font-size: .28rem;
          display: flex;
          padding-top: .76rem;
          .sec-1-content-point-text {
            display: block;
            width: 50%;
            .sec-1-content-point-text-line {
              padding-right: .2rem;
            }
          }
        }
        
        .sec-1-content-detail {
          padding-top: .34rem;
          width: 5.2rem;
          font-size: .16rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-1-content-detail-icon {
            padding-right: .16rem;
          }
        }

      }
    }
    // p2 css
    .home-section-2 {
      height: 12.3rem;
      position: relative;

      .sec-2-img-1 {
        width: 9.75rem;
        height: 8.8rem;
        position: absolute;
        top: 0;
        left: 0;
      }
      .sec-2-content {
        position: absolute;
        top: 3.41rem;
        left: 10.84rem;

        .sec-2-content-title {
          font-size: 1rem;
          color: #F0F2F4;
        }
        
        .sec-2-content-detail {
          padding-top: .78rem;
          width: 6.92rem;
          font-size: .16rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-2-content-detail-icon {
            padding-right: .16rem;
          }
        }

      }
    }
    // p3 css
    .home-section-3 {
      height: 12.3rem;
      position: relative;

      .sec-3-img-1 {
        width: 10.3rem;
        height: 8.75rem;
        position: absolute;
        top: .5rem;
        left: .67rem;
      }
      .sec-3-content {
        position: absolute;
        top: 0;
        left: 10.84rem;

        .sec-3-content-title {
          font-size: 1rem;
          color: #F0F2F4;
        }

        .sec-3-content-point {
          font-size: .28rem;
          color: #006DA0;
          padding-top: .78rem;
        }
        
        .sec-3-content-detail {
          padding-top: .78rem;
          width: 6.92rem;
          font-size: .16rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-3-content-detail-icon {
            padding-right: .16rem;
          }
        }

        .sec-3-content-button {
          cursor: pointer;
          color: #006DA0;
          width: 2.04rem;
          height: .61rem;
          border: 1px solid #006DA0;
          border-radius: .3rem;
          margin-top: .78rem;

          p {
            height: .6rem;
            line-height: .6rem;
            text-align: center;
            font-size: .16rem;
          }
        }
      }
    }
    // p4 css
    .home-section-4 {
      display: flex;
      padding: 0 1.5rem 1.35rem;
      flex-direction: row;
      justify-content: space-between;

      .sec-4-item {
        width: 4.8rem;

        .sec-4-item-img {
          width: 4.8rem;
          height: 6rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .sec-4-item-content {
          .sec-4-item-content-title {
            font-size: .28rem;
            color: #fff;
            margin-top: .32rem;
            line-height: .6rem;
            p {
              display: block;
              width: 4.8rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .sec-4-item-content-time {
            font-size: .16rem;
            margin-top: .2rem;
            color: #C2C3C9;
          }
          .sec-4-item-content-button {
            cursor: pointer;
            color: #006DA0;
            width: 2.04rem;
            height: .6rem;
            border: 1px solid #006DA0;
            border-radius: .3rem;
            margin-top: .8rem;

            p {
              height: .6rem;
              line-height: .6rem;
              text-align: center;
              font-size: .16rem;
            }
          }
        }
      }

    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .home {
    // p0 css
    .home-section-0 {
      position: relative;
      min-height: 6rem;
      padding-bottom: 1.23rem;

      .sec-0-img-0 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: 0;
        left: .4rem;
      }

      .sec-0-img-1 {
        width: 7rem;
      }
      .sec-0-content {
        .sec-0-content-img {
          width: 5.5rem;
          margin: .5rem 1rem 0 1rem;
        }
        
        .sec-0-content-detail {
          padding-left: .6rem;
          padding-right: .6rem;
          padding-top: .18rem;
          font-size: .22rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-0-content-detail-icon {
            padding-right: .16rem;
          }
        }

      }
      
      .sec-1-point {
        display: none;
      }
    }
    // p1 css
    .home-section-1 {
      position: relative;
      padding-top: .7rem;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: 0;
        left: .4rem;
        display: none;
      }
      .sec-1-img-2 {
        width: 5.6rem;
        height: 6.18rem;
        padding-left: 1.5rem;
      }
      .sec-1-point {
        display: none;
      }
      .sec-1-content {
        .sec-1-content-title {
          margin-top: .9rem;
          padding-left: .41rem;
          height: .8rem;
          line-height: .8rem;
          font-size: .72rem;
          color: #F0F2F4;
          font-family: 'SourceHanSansCNBold';

          .sec-1-content-title-img {
            height: .8rem;
            width: .8rem;
            position: relative;
            
            img {
              position: absolute;
              width: .72rem;
              height: .72rem;
              top: 0.04rem;
              left: 0.2rem;
            }
          }
          
        }

        .sec-1-content-point {
          height: .3rem;
          color: #fff;
          font-size: .28rem;
          padding-top: .5rem;
          padding-left: .4rem;
          font-family: 'SourceHanSansCNBold';
          .sec-1-content-point-text {
            float: left;
            display: block;
            margin-right: .4rem;
            .sec-1-content-point-text-line {
              padding-right: .2rem;
            }
          }
        }
        
        .sec-1-content-detail {
          padding: .5rem .4rem 1.42rem .4rem;
          font-size: .22rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-1-content-detail-icon {
            padding-right: .16rem;
          }
        }

      }
    }
    // p2 css
    .home-section-2 {
      min-height: 6rem;
      padding-bottom: 1.23rem;
      .sec-2-img-1 {
        width: 6.9rem;
      }
      .sec-2-content {
        .sec-2-content-title {
          font-family: 'SourceHanSansCNBold';
          font-size: .72rem;
          color: #fff;
          padding-right: .42rem;
          text-align: right;
        }
        
        .sec-2-content-detail {
          padding-left: 1.9rem;
          padding-right: .4rem;
          padding-top: .78rem;
          font-size: .22rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-2-content-detail-icon {
            padding-right: .16rem;
          }
        }

      }
    }
    // p3 css
    .home-section-3 {
      min-height: 10rem;
      .sec-3-img-1 {
        width: 6.2rem;
      }
      .sec-3-content {
        padding-bottom: 1.4rem;
        width: 5.6rem;
        margin-left: 1.9rem;

        p {
          display: block;
          width: 100%;
        }

        .sec-3-content-title {
          font-size: .72rem;
          color: #fff;
          font-family: 'SourceHanSansCNBold';
          padding-top: .64rem;
        }

        .sec-3-content-point {
          font-size: .28rem;
          font-family: 'SourceHanSansCNBold';
          color: #006DA0;
          padding-top: .5rem;
        }
        
        .sec-3-content-detail {
          padding-top: .4rem;
          font-size: .22rem;
          color: #C0C2C4;
          line-height: .42rem;

          .sec-3-content-detail-icon {
            padding-right: .16rem;
          }
        }

        .sec-3-content-button {
          cursor: pointer;
          color: #006DA0;
          width: 2.04rem;
          height: .6rem;
          border: 1px solid #006DA0;
          border-radius: .3rem;
          margin-top: .4rem;

          p {
            height: .6rem;
            line-height: .6rem;
            text-align: center;
            font-size: .16rem;
          }
        }
      }
    }
    // p4 css
    .home-section-4 {
      padding: 0 .56rem .52rem .4rem;

      .sec-4-item {
        height: 5.06rem;

        .sec-4-item-img {
          width: 3.6rem;
          height: 4.5rem;
          float: left;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .sec-4-item-content {
          float: left;
          width: 2.4rem;
          margin-left: .53rem;
          padding-top: .6rem;

          .sec-4-item-content-title {
            font-size: .22rem;
            color: #F0F2F4;
            font-family: 'SourceHanSansCNBold';
            line-height: .48rem;
          }
          .sec-4-item-content-time {
            font-size: .22rem;
            margin-top: .3rem;
            color: #C2C3C9;
          }
          .sec-4-item-content-button {
            cursor: pointer;
            color: #006DA0;
            width: 2.02rem;
            height: .6rem;
            border: 1px solid #006DA0;
            border-radius: .3rem;
            margin-top: .36rem;

            p {
              height: .6rem;
              line-height: .6rem;
              text-align: center;
              font-size: .16rem;
            }
          }
        }
      }

    }
  }

  .home_en {
    .sec-1-content-point {
      padding-top: 1.5rem!important;
    }
  }
}
</style>